import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from '@remix-run/react';
import {json, type LoaderFunctionArgs} from '@remix-run/node';
import {useTranslation} from 'react-i18next';

import {env, isDevelopment, isSpin, isStaging} from '~/utils/env';

import remixI18n from './i18next.server';

import {BugsnagConfiguration} from '@shopify/remix-bugsnag';

import {BUGSNAG_API_KEY} from './constants';

export async function loader({request}: LoaderFunctionArgs) {
  const locale = await remixI18n.getLocale(request);

  const stage = isStaging ? 'staging' : 'production';
  const releaseStage = isSpin ? 'development' : stage;

  return json({
    locale,
    bugsnagConfig: {
      apiKey: isSpin ? '0'.repeat(32) : BUGSNAG_API_KEY,
      releaseStage,
      appVersion: env('SHOPIFY_BUILD_COMMIT'),
    },
  });
}

export let handle = {i18n: 'common'};

export default function App() {
  const {locale} = useLoaderData<typeof loader>();
  const {i18n} = useTranslation(handle.i18n);

  return (
    <html lang={locale} dir={i18n.dir()}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <meta name="robots" content="none" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Meta />
        <BugsnagConfiguration />
        <link rel="preconnect" href="https://fonts.googleapis.com/" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com/"
          crossOrigin="anonymous"
        />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@450;550;650;700&display=swap" />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        {isDevelopment ? <script src="http://localhost:8097"></script> : null}
      </body>
    </html>
  );
}
